import React  from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Navigate } from 'react-router-dom';
// import Link from '@mui/material/Link';
import { account } from '_mock/account';

import AuthService from "services/auth.service";


// ----------------------------------------------------------------------
class AccountPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      logout: false,
      User: "",
      dashboardOPen: false,
    };
  }
  componentDidMount() {
    this.setState({
      User: AuthService.getCurrentUser(),

    });
    console.log("AuthService.getCurrentUser() : ", AuthService.getCurrentUser().username);
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.loginResponse !== this.state.loginResponse) {

    }
  }
  handleOpen = (event) => {
    this.setState({
      open: event.currentTarget,

    });
  };

  handleClose = () => {
    this.setState({
      open: null,

    });
  };
  logoutFromApp = () => {
    AuthService.logout()
    this.setState({
      logout: true,

    });
  };


  render() {
    if (this.state.logout === true) {
      return (<Navigate to="/login" replace={true} />);
    } else if (this.state.dashboardOPen === true) {
      return (<Navigate to="/school" replace={true} />);
    } else {
      return (
        <>
          <IconButton
            onClick={this.handleOpen}
            sx={{
              width: 40,
              height: 40,
              background: (theme) => alpha(theme.palette.grey[500], 0.08),
              ...(this.state.open && {
                background: (theme) =>
                  `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
              }),
            }}
          >
            <Avatar
              src={account.photoURL}
              alt={account.displayName}
              sx={{
                width: 36,
                height: 36,
                border: (theme) => `solid 2px ${theme.palette.background.default}`,
              }}
            >
              {account.displayName.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>

          <Popover
            open={!!this.state.open}
            anchorEl={this.state.open}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 0,
                mt: 1,
                ml: 0.75,
                width: 200,
              },
            }}
          >
            <Box sx={{ my: 1.5, px: 2 }}>
              <Typography variant="subtitle2" noWrap>
                { AuthService.getCurrentUser().username}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                { AuthService.getCurrentUser().email}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />
              <MenuItem key={0} 
                >
                Home
              </MenuItem>

           

            <MenuItem key={1} onClick={this.handleClose}>
              Profile
            </MenuItem>
            <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

            <MenuItem
              disableRipple
              disableTouchRipple
              onClick={this.logoutFromApp}
              sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
            >
              Logout
            </MenuItem>
          </Popover>
        </>
      );
    }
  }
}
export default AccountPopover;
