
class AuthService {

  logout() {
    localStorage.setItem('LoggedIn',  false);
    // localStorage.removeItem('user');
  }



  SetLoggedIn(user) {
    localStorage.setItem('LoggedIn',  user);
  }
  SetCurrentUser(user) {
    localStorage.setItem('user',  JSON.stringify(user));
  }
  SetCurrentUserType(user) {
    localStorage.setItem('userType',  user);
  }
  SetLoginPhoneNumber(phone) {
    localStorage.setItem('userPhone',  phone);
  }


  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  getLoggedIn() {
    return localStorage.getItem('LoggedIn');
  }
  getCurrentUserType() {
    return localStorage.getItem('userType');
  }
  getCurrentPhoneNumber() {
    return localStorage.getItem('userPhone');
  }


}

export default new AuthService();
