import 'global.css';

import { useScrollToTop } from 'hooks/use-scroll-to-top';

import Router from 'routes/sections';
import ThemeProvider from 'theme';
import { ConfigProvider } from 'antd';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <ThemeProvider>
        <Router />
    </ThemeProvider>
  );
}
