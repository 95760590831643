import React from 'react';
import PropTypes from 'prop-types';
// import { set, sub } from 'date-fns';
// import { faker } from '@faker-js/faker';
import axios from "axios";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
// import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import mqtt from 'mqtt';
import { Flex, message, Upload, notification } from 'antd';

import AuthService from "services/auth.service";
import HomeIcon from '@mui/icons-material/Home';

import { fToNow } from 'utils/format-time';

import Iconify from 'components/iconify';
import Scrollbar from 'components/scrollbar';


class NotificationsPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      logout: false,
      notices: [],
      record: {
        protocol: 'ws',
        host: "13.60.32.211",
        clientId: `neutron`,
        port: 8000,
        username: "admin_admin",
        password: "admin_user",
      },
      client: null,
      connectStatus: "Connect",
      isSubed: false,
      messages: [],
      qos: 1,
    };
  }
  componentDidMount() {
    this.handleConnect();
    axios
    .get('https://school-api.atomiot.co.ke/v1/school/get/notices?schoolId=' + AuthService.getCurrentUser().schoolId)
    .then((res) => {
      this.setState({ notices: res.data });
      console.log(res.data);
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.loginResponse !== this.state.loginResponse) {

    }
  }
  handleConnect = () => {
    this.setState({ connectStatus: 'Connecting' })
    /**
     * if protocol is "ws", connectUrl = "ws://broker.emqx.io:8083/mqtt"
     * if protocol is "wss", connectUrl = "wss://broker.emqx.io:8084/mqtt"
     *
     * /mqtt: MQTT-WebSocket uniformly uses /path as the connection path,
     * which should be specified when connecting, and the path used on EMQX is /mqtt.
     *
     * for more details about "mqtt.connect" method & options,
     * please refer to https://github.com/mqttjs/MQTT.js#mqttconnecturl-options
     */
   

    const url = `${this.state.record.protocol}://${this.state.record.host}:${this.state.record.port}/mqtt`
    const options = {
      clientId: this.state.record.clientId,
      username: this.state.record.username,
      password: this.state.record.password,
      clean: true,
      reconnectPeriod: 1000, // ms
      connectTimeout: 30 * 1000, // ms
    }
    const subs = 'school/18e399f6b870/1e0e883bf0ce';
    // const subs = '#';
    const pub = 'security/IO/neutron';
    // const client = mqtt.connect(url, options)
    // if (client) {
    //   // https://github.com/mqttjs/MQTT.js#event-connect
    //   client.on('connect', () => {
    //     this.setState({ connectStatus: 'Connected' })
    //     client.subscribe(subs, function (err) {
    //       if (!err) {
    //         // Publish a message to a topic
    //         // client.publish(pub, 'GetPowerStatus')
    //         // this.client.publish(pub, 'inputs')
    //       }
    //     })
    //     console.log('connection successful')
    //   })

    //   // https://github.com/mqttjs/MQTT.js#event-error
    //   client.on('error', (err) => {
    //     console.error('Connection error: ', err)
    //     client.end()
    //   })

    //   // https://github.com/mqttjs/MQTT.js#event-reconnect
    //   client.on('reconnect', () => {
    //     this.setState({ connectStatus: 'Reconnecting' })
    //   })

    //   // https://github.com/mqttjs/MQTT.js#event-message
    //   client.on("message", (topic, message) => {
    //     const payload = { topic, message: message.toString() };
    //     // console.log(JSON.parse(message));
    //     // this.openInfoNotification("topLeft", message.toString());
    //     // const data={
    //     //   avatar: <HomeIcon />,
    //     //   title: JSON.parse(message).School.SchName,
    //     //   createdAt: new Date(),
    //     // }
    //     // this.state.notices.push(data);
    //     // console.log(payload );
    //     // this.setState({ 
    //     //   notices:  data,
    //     //  });
    //     // if (Object.keys(JSON.parse(message))[0] == "0_MainsID") {
    //     //   this.setState({ outputs_disp: JSON.parse(message) });
    //     //   const devid = JSON.parse(message)["0_MainsID"];
    //     //   if (devid == this.props.deviceID) {
    //     //     this.setState({ con_status: "connected" });
    //     //     this.setState({ con_color: "#569d59" });
    //     //   }
    //       // }else  if (Object.keys(JSON.parse(message))[0] == "0_DeviceID") {

    //       //   if("requestType" in JSON.parse(message)){  
    //       //     const devid = JSON.parse(message)["0_DeviceID"];
    //       //     if (devid == this.props.deviceID) {
    //       //       this.setState({ con_status: "connected" });
    //       //       this.setState({ con_color: "#569d59" });
    //       //     }


    //       //   }else{
    //       //     this.setState({ outputs_disp: JSON.parse(message) });
    //       //     const devid = JSON.parse(message)["0_DeviceID"];
    //       //     if (devid == this.props.deviceID) {
    //       //       this.setState({ con_status: "connected" });
    //       //       this.setState({ con_color: "#569d59" });
    //       //     }
    //       //   }

    //       // }else if (Object.keys(JSON.parse(message))[0] == "PowerID") {

    //       //   const devid = JSON.parse(message)["PowerID"];
    //       //   if (devid == this.props.deviceID) {
    //       //     const stima_id = JSON.parse(message)["PowerID"];
    //       //     this.setState({ power_title: JSON.parse(message)["Stima"] });
    //       //     if (stima_id == "OFF") {
    //       //       this.setState({ power_color: "light", power_switch: false, });
    //       //     } else if (stima_id == "ON") {
    //       //       this.setState({ power_color: "info", power_switch: true, });
    //       //     }
    //       //   }
    //     // }
    //     // const { messages } = this.state;
    //     // if (payload.topic) {
    //     //   const changedMessages = messages.concat([payload]);
    //     //   this.setState({ messages: changedMessages });
    //     // }
    //   });
    // }
  }
  openInfoNotification = (placement, message) => {
    notification.success({
      message: `Notification `,
      description: message,
      placement,
    });
  };
  handleOpen = (event) => {
    this.setState({
      open: event.currentTarget,

    });
  };

  handleClose = () => {
    this.setState({
      open: null,

    });
  };
  logoutFromApp = () => {
    AuthService.logout()
    this.setState({
      logout: true,
    });
  };

  handleMarkAllAsRead = () => {
    this.setState({
      notices: this.state.notices.map((notification) => ({
        ...notification,
        isUnRead: false,
      })),

    });

  };

  render() {


    // const totalUnRead = this.state.notices.filter((item) => item.isUnRead === true).length;

    return (
      <>
        <IconButton color={this.state.open ? 'primary' : 'default'} onClick={this.handleOpen}>
          <Badge  color="error">
            <Iconify width={24} icon="solar:bell-bing-bold-duotone" />
          </Badge>
        </IconButton>

        <Popover
          open={!!this.state.open}
          anchorEl={this.state.open}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              mt: 1.5,
              ml: 0.75,
              width: 360,
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">Notifications</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {/* You have {totalUnRead} unread messages */}
              </Typography>
            </Box>

            {/* {totalUnRead > 0 && (
              <Tooltip title=" Mark all as read">
                <IconButton color="primary" onClick={handleMarkAllAsRead}>
                  <Iconify icon="eva:done-all-fill" />
                </IconButton>
              </Tooltip>
            )} */}
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
           
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  School Notices
                </ListSubheader>
              }
            >
              {this.state.notices.slice(2, 5).map((notification, key) => (
                <NotificationItem key={key} notification={notification} />
              ))}
            </List>
          </Scrollbar>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Box sx={{ p: 1 }}>
          <a href="/school/notices" >
            <Button fullWidth disableRipple>
              View All
            </Button>

            </a>
          </Box>
        </Popover>
      </>
    );
  }
}

export default NotificationsPopover;

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    Name: PropTypes.string,
    DriverID: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.message}
      </Typography>
    </Typography>
  );

  return {
    avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
    title,
  };
}
