import SvgColor from 'components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/shule',
    icon: icon('ic_analytics'),
  },
  {
    title: 'schools',
    path: '/shule/schools',
    icon: icon('ic_user'),
  },
  {
    title: 'devices',
    path: '/shule/devices',
    icon: icon('bus'),
  },
  {
    title: 'buses',
    path: '/shule/buses',
    icon: icon('bus'),
  },
  {
    title: 'drivers',
    path: '/shule/drivers',
    icon: icon('users'),
  },
  {
    title: 'technicians',
    path: '/shule/technicians',
    icon: icon('settings-plus'),
  },
  {
    title: 'payments',
    path: '/shule/payments',
    icon: icon('ic_blog'),
  },
  {
    title: 'users',
    path: '/shule/users',
    icon: icon('ic_blog'),
  },

];

export default navConfig;
