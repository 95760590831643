import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import SchoolLayout from 'views/School/School';
import AdminSchoolLayout from 'views/AdminSchools/AdminSchools';
import AdminLindaLayout from 'views/AdminLinda/AdminLinda';

// SchoolProfile


// import DashboardLayout from 'layouts/dashboard';
// export const IndexPage = lazy(() => import('pages/app'));
// export const BlogPage = lazy(() => import('pages/blog'));
// export const UserPage = lazy(() => import('pages/user'));
// export const ProductsPage = lazy(() => import('pages/products'));
export const Page404 = lazy(() => import('pages/page-not-found'));
export const LoginPage = lazy(() => import('views/Login'));

export const SchoolDashboard = lazy(() => import('views/School/dashboard'));
export const SchoolBilling = lazy(() => import('views/School/billing'));
export const SchoolBuses = lazy(() => import('views/School/buses'));
export const SchoolDrivers = lazy(() => import('views/School/drivers'));
export const SchoolNotices = lazy(() => import('views/School/notices'));
export const SchoolParents = lazy(() => import('views/School/parents'));
export const SchoolUsers = lazy(() => import('views/School/users'));
export const SchoolProfile = lazy(() => import('views/School/profile'));

export const AdminSchoolDashboard = lazy(() => import('views/AdminSchools/dashboard'));
export const AdminSchoolSchools = lazy(() => import('views/AdminSchools/schools'));
export const AdminSchoolBilling = lazy(() => import('views/AdminSchools/payments'));
export const AdminSchoolBuses = lazy(() => import('views/AdminSchools/buses'));
export const AdminSchoolDevices = lazy(() => import('views/AdminSchools/devices'));
export const AdminSchoolUsers = lazy(() => import('views/AdminSchools/users'));
export const AdminSchoolTechnicians = lazy(() => import('views/AdminSchools/technicians'));
export const AdminSchoolDrivers = lazy(() => import('views/AdminSchools/drivers'));
export const AdminSchoolPayments = lazy(() => import('views/AdminSchools/payments'));
export const AdminSchoolProfile = lazy(() => import('views/AdminSchools/profile'));

export const AdminLindaDashboard = lazy(() => import('views/AdminLinda/dashboard'));
export const AdminLindaCustomers = lazy(() => import('views/AdminLinda/customers'));
export const AdminLindaBilling = lazy(() => import('views/AdminLinda/payments'));
export const AdminLindaBuses = lazy(() => import('views/AdminLinda/buses'));
export const AdminLindaTechnicians = lazy(() => import('views/AdminLinda/technicians'));
export const AdminLindaDrivers = lazy(() => import('views/AdminLinda/drivers'));
export const AdminLindaPayments = lazy(() => import('views/AdminLinda/payments'));
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([

    {
      element: (
        <SchoolLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </SchoolLayout>
      ),
      children: [
        {  path: '/school', element: <SchoolDashboard />, index: true },
        { path: '/school/buses', element: <SchoolBuses /> },
        { path: '/school/parents', element: <SchoolParents /> },
        { path: '/school/billing', element: <SchoolBilling /> },
        { path: '/school/drivers', element: <SchoolDrivers /> },
        { path: '/school/notices', element: <SchoolNotices /> },
        { path: '/school/users', element: <SchoolUsers /> },
        { path: '/school/profile', element: <SchoolProfile /> },
      ],
    },
    {
      element: (
        <AdminSchoolLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AdminSchoolLayout> 
      ),
      children: [
        { path: '/shule', element: <AdminSchoolDashboard />, index: true },
        { path: '/shule/schools', element: <AdminSchoolSchools /> },
        { path: '/shule/billing', element: <AdminSchoolBilling /> },
        { path: '/shule/buses', element: <AdminSchoolBuses /> },
        { path: '/shule/profile', element: <AdminSchoolProfile /> },
        { path: '/shule/users', element: <AdminSchoolUsers /> },
        { path: '/shule/devices', element: <AdminSchoolDevices /> },
        { path: '/shule/drivers', element: <AdminSchoolDrivers /> },
        { path: '/shule/technicians', element: <AdminSchoolTechnicians /> },
        { path: '/shule/payments', element: <AdminSchoolPayments /> },
      ],
    },
    {
      element: (
        <AdminLindaLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AdminLindaLayout> 
      ),
      children: [
        { path: '/linda', element: <AdminSchoolDashboard />, index: true },
        { path: '/linda/customers', element: <AdminLindaCustomers /> },
        { path: '/linda/billing', element: <AdminSchoolBilling /> },
        { path: '/linda/buses', element: <AdminSchoolBuses /> },
        { path: '/linda/drivers', element: <AdminSchoolDrivers /> },
        { path: '/linda/technicians', element: <AdminSchoolTechnicians /> },
        { path: '/linda/payments', element: <AdminSchoolPayments /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
