import SvgColor from 'components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/school',
    icon: icon('ic_analytics'),
  },
  {
    title: 'parents',
    path: '/school/parents',
    icon: icon('ic_user'),
  },
  {
    title: 'buses',
    path: '/school/buses',
    icon: icon('bus'),
  },
  {
    title: 'notices',
    path: '/school/notices',
    icon: icon('bell'),
  },
  {
    title: 'drivers',
    path: '/school/drivers',
    icon: icon('users'),
  },
  {
    title: 'users',
    path: '/school/users',
    icon: icon('users'),
  }, 

];

export default navConfig;
