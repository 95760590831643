import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import { usePathname } from 'routes/hooks';
import { RouterLink } from 'routes/components';

import { useResponsive } from 'hooks/use-responsive';
import { Navigate } from 'react-router-dom';

import { account } from '_mock/account';

import Logo from 'components/logo';
import Scrollbar from 'components/scrollbar';

import { NAV } from './config-layout';
import navConfig from './config-navigation';
import AuthService from "services/auth.service";

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      User: "",
      LoggedIn: null,
      password: "",
      loginResponse: [],
      UserLoggedIn: false,
      userType: "",
      lgUp: "up",
    };
  }
  componentDidMount() {
    if (this.props.openNav) {
      this.props.onCloseNav();

    }
    this.setState({
      User: AuthService.getCurrentUser(),

    });
    if (AuthService.getLoggedIn === true) {
      this.setState({
        LoggedIn: true,
      });
    } else {
      this.setState({
        LoggedIn: false,
      });
    }

  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.loginResponse !== this.state.loginResponse) {

    }
  }



  render() {
    if (AuthService.getLoggedIn() === "false") {
      return (<Navigate to="/login" replace={true} />);
    } else {
      const upLg = this.props.upLg;


      const renderAccount = (
        <Box
          sx={{
            my: 3,
            mx: 2.5,
            py: 2,
            px: 2.5,
            display: 'flex',
            borderRadius: 1.5,
            alignItems: 'center',
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
          }}
        >
          <Avatar src={account.photoURL} alt="photoURL" />

          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2">{this.state.User.userName}</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {this.state.User.school}
            </Typography>
          </Box>
        </Box>
      );

      const renderMenu = (
        <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
          {navConfig.map((item) => (
            <NavItem key={item.title} item={item} />
          ))}
        </Stack>
      );

      const renderContent = (
        <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': {
              height: 1,
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <Logo sx={{ mt: 3, ml: 4 }} />

          {renderAccount}

          {renderMenu}

          <Box sx={{ flexGrow: 1 }} />

        </Scrollbar>
      );

      return (
        <Box
          sx={{
            flexShrink: { lg: 0 },
            width: { lg: NAV.WIDTH },
          }}
        >

          {upLg ? (
            <Box
              sx={{
                height: 1,
                position: 'fixed',
                width: NAV.WIDTH,
                borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
              }}
            >
              {renderContent}
            </Box>
          ) : (
            <Drawer
              open={this.props.openNav}
              onClose={this.props.onCloseNav}
              PaperProps={{
                sx: {
                  width: NAV.WIDTH,
                },
              }}
            >
              {renderContent}
            </Drawer>
          )}
        </Box>
      );
    }

  }
}
function withHooks(WrappedComponent) {
  return function (props) {
    // const pathname = usePathname();

    const upLg = useResponsive('up', 'lg');
    return (
      < WrappedComponent upLg={upLg} {...props} />
    );
  };
}
export default withHooks(Nav);
// Nav.propTypes = {
//   openNav: PropTypes.bool,
//   onCloseNav: PropTypes.func,
// };

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const pathname = usePathname();

  const active = item.path === pathname;

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
