import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'routes/components';
import './styles.css';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
	const theme = useTheme();

	const PRIMARY_LIGHT = theme.palette.primary.light;

	const PRIMARY_MAIN = theme.palette.primary.main;

	const PRIMARY_DARK = theme.palette.primary.dark;

	// OR using local (public folder)
	// -------------------------------------------------------
	// const logo = (
	//   <Box
	//     component="img"
	//     // src="/assets/Neutron.svg"
	//     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
	//   >
	//     <img src='/assets/Neutron.svg' alt="neutron" width="100%" height="100%" viewBox="0 0 512 512" />
	//   </Box>
	// );

	const logo = (
		<Box
			ref={ref}
			component="div"
			sx={{
				width: "80%",
				height: 180,
				display: 'inline-flex',

				...sx,
			}}
			{...other}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 100 512 250" >
				

				<path class="st0" d="M132.57,206.42c15.04,9.13,21.76,30.82,15.45,49c-1.71,4.92-4.39,9.25-7.47,13.41
	c-2.34,3.17-4.28,3.3-7.11,0.39c-10.55-10.86-21.3-21.53-31.92-32.32c-1.15-1.17-1.9-2.72-2.53-3.64c-2.92,1.03-2,2.41-2.01,3.37
	c-0.06,11.15,0.02,22.3-0.07,33.45c-0.02,1.93,1.34,4.61-1.43,5.62c-2.06,0.75-7.52-3.46-7.9-5.67c-0.06-0.32-0.04-0.66-0.04-1
	c0-19.1,0-38.21,0-58.18c1.82,1.67,3.13,2.79,4.35,4c13.54,13.52,27.19,26.94,40.51,40.68c3.41,3.52,4.84,2.31,6.45-1.11
	c7.14-15.15,5.45-29.58-3.58-43.39c-0.81-1.24-1.8-2.37-2.69-3.56C132.5,207.36,132.57,207.15,132.57,206.42z"/>
				<path class="st1" d="M421.51,232.94c0,10.95-0.09,21.89,0.06,32.84c0.04,2.87-0.44,4.1-3.75,4.03c-3.13-0.07-3.91-1.01-3.88-4.01
	c0.13-13.82-0.03-27.65,0.12-41.47c0.02-1.82-1.7-4.89,1.48-5.39c2.67-0.42,5.61-1.1,7.96,2.18c6.68,9.32,13.82,18.32,20.78,27.44
	c1.79,2.35,3.57,4.7,6.25,8.22c0-9.5,0-17.7,0-25.9c0-2.5,0.3-5.04-0.06-7.48c-0.64-4.38,1.63-4.87,5.14-4.72
	c2.14,0.09,2.85,0.64,2.83,2.83c-0.08,15.15-0.03,30.31-0.07,45.46c0,1.02,0.53,2.68-1.3,2.65c-2.99-0.05-6.02,0.97-8.47-2.4
	c-8.22-11.3-16.78-22.34-25.21-33.49c-0.3-0.39-0.5-0.86-0.75-1.29C422.26,232.6,421.89,232.77,421.51,232.94z"/>
				<path class="st2" d="M332.3,251.46c4.05,5.85,8.08,11.66,12.2,17.62c-8.05,1.25-8.04,1.25-12.33-4.98c-2.26-3.28-4.6-6.5-6.83-9.81
	c-1.11-1.65-2.43-2.33-4.51-2.38c-8.52-0.22-8.52-0.31-8.54,8.15c0,1.66-0.24,3.36,0.03,4.98c0.72,4.34-1.53,4.9-5.11,4.72
	c-2.16-0.11-2.83-0.66-2.82-2.83c0.08-15.13,0.1-30.26-0.02-45.39c-0.02-2.71,1.24-2.86,3.34-2.83c5.99,0.1,11.97-0.01,17.96,0.06
	c10.02,0.11,17.28,5.12,18.82,12.9C346.27,240.68,342.62,246.7,332.3,251.46z"/>
				<path class="st2" d="M377.53,217.87c14.38-0.05,26.44,11.7,26.73,26.02c0.28,13.93-12.03,26.56-26.13,26.8
	c-14.4,0.24-26.81-11.99-26.84-26.46C351.25,229.82,363.1,217.92,377.53,217.87z"/>
				<path class="st1" d="M155.51,244.06c0-7.32,0.09-14.65-0.05-21.97c-0.05-2.5,0.53-3.45,3.26-3.41c10.32,0.16,20.64,0.15,30.96,0.01
	c2.72-0.04,3.69,0.61,3.79,3.54c0.12,3.38-1.28,3.76-4.09,3.7c-7.49-0.16-14.98,0.03-22.47-0.1c-2.44-0.04-3.41,0.4-3.48,3.19
	c-0.27,10.99-0.4,10.99,10.64,10.99c4.33,0,8.66,0.14,12.98-0.05c2.71-0.12,3.4,0.96,3.37,3.49c-0.03,2.54-0.36,4-3.49,3.9
	c-6.49-0.21-12.99,0.08-19.47-0.1c-2.88-0.08-4.03,0.46-4.05,3.75c-0.07,11.73-0.22,11.73,11.45,11.73c5.16,0,10.32,0.11,15.48-0.04
	c2.47-0.07,3.18,0.87,3.05,3.19c-0.12,2.06,0.23,3.91-2.95,3.87c-10.65-0.15-21.31-0.14-31.96-0.01c-2.53,0.03-3.05-0.93-3.02-3.21
	C155.58,259.04,155.51,251.55,155.51,244.06z"/>
				<path class="st3" d="M246.48,236.45c0,3.99,0.01,7.98,0,11.97c-0.04,12.93-9.07,22.18-21.74,22.27
	c-12.34,0.09-21.67-8.86-22.04-21.53c-0.26-8.97,0.12-17.96-0.15-26.93c-0.11-3.64,1.76-3.44,4.29-3.56
	c2.67-0.12,3.81,0.41,3.73,3.45c-0.21,8.64-0.11,17.29-0.06,25.93c0.05,9.03,5.87,15.5,13.97,15.64
	c7.99,0.14,14.04-6.38,14.13-15.41c0.08-8.64,0.19-17.29-0.07-25.93c-0.1-3.41,1.37-3.6,4.12-3.71c3.06-0.12,4.08,0.69,3.9,3.83
	C246.28,227.13,246.49,231.8,246.48,236.45z"/>
				<path class="st4" d="M279.47,247.79c0,6.15-0.15,12.3,0.07,18.44c0.1,2.93-0.95,3.65-3.68,3.52c-2.54-0.11-4.38,0.05-4.31-3.56
	c0.22-11.96-0.11-23.93,0.19-35.88c0.1-3.92-1.16-4.89-4.73-4.49c-2.46,0.28-4.98,0.02-7.47,0.06c-2.34,0.04-4.35,0.45-4.35-3.34
	c0.01-3.66,1.52-3.88,4.4-3.84c10.97,0.13,21.94,0.17,32.9-0.02c3.07-0.05,3.92,0.79,3.93,3.88c0.01,3.21-1.43,3.6-3.92,3.32
	c-0.16-0.02-0.33-0.01-0.5,0c-4.08,0.35-9.51-1.83-11.87,0.9c-2,2.31-0.6,7.58-0.65,11.54
	C279.43,241.48,279.47,244.64,279.47,247.79z"/>
				<path class="st5" d="M86.15,279.59c7.43-0.01,12.5,4.87,12.36,11.88c-0.13,6.38-5.34,11.34-12.01,11.41
	c-7.2,0.08-12.04-4.56-12.07-11.59C74.4,283.9,78.71,279.6,86.15,279.59z"/>
				<path class="st5" d="M48.3,267.96c-0.08-7.39,4.33-12.15,11.34-12.22c7.15-0.07,11.88,4.52,12.01,11.63
	c0.13,7.07-4.63,12.26-11.4,12.42C53.47,279.97,48.38,274.92,48.3,267.96z"/>
				<path class="st5" d="M143.16,164.85c5.57,0.12,10.58,5.26,10.34,10.63c-0.23,5.24-5.26,10.07-10.52,10.09
	c-5.48,0.02-10.47-5.01-10.48-10.57C132.49,169.36,137.35,164.73,143.16,164.85z"/>
				<path class="st6" d="M115.29,299.2c-0.08-5.33,3.28-9.19,8.15-9.35c4.8-0.16,8.92,3.74,9.1,8.61c0.19,5.12-3.82,9.25-8.94,9.23
	C118.59,307.67,115.36,304.38,115.29,299.2z"/>
				<path class="st7" d="M163.76,290.75c0.07,5.36-3.28,9.03-8.31,9.08c-4.88,0.06-8.89-3.88-8.91-8.74c-0.02-4.82,3.98-9.12,8.58-9.22
	C159.98,281.75,163.69,285.58,163.76,290.75z"/>
				<path class="st8" d="M96.39,168.92c-0.09-5.18,2.7-8.11,7.8-8.19c4.83-0.08,8.28,3.16,8.35,7.85c0.07,4.73-3.26,8.21-7.98,8.34
	C99.83,177.06,96.47,173.77,96.39,168.92z"/>
				<path class="st9" d="M175.6,200.21c0.04,4.11-2.59,6.73-6.81,6.78c-4.02,0.05-7.07-2.83-7.1-6.69c-0.03-3.75,3.21-6.98,6.98-6.97
	C172.57,193.34,175.56,196.31,175.6,200.21z"/>
				<path class="st10" d="M56.58,240.89c-0.39,3.11-2.51,5.23-6.54,4.97c-3.71-0.24-6.41-1.78-6.47-6.02c-0.05-4.02,2.93-7.01,6.89-6.94
	C53.88,232.95,56.68,236.22,56.58,240.89z"/>
				<path class="st9" d="M70.25,191.89c-3.73-0.11-5.97-2.82-5.92-7.08c0.05-4.18,2.39-6.08,6.29-5.94c3.8,0.14,6.22,2.44,6.59,6.16
	C77.58,188.83,74.08,192.01,70.25,191.89z"/>
				<path class="st11" d="M51.59,208.62c3.12-0.08,5.08,0.96,5.12,4.52c0.03,3.27-1.23,4.93-4.63,5.01c-3.25,0.07-5.12-1.45-5.3-4.58
	C46.6,210.38,48.36,208.68,51.59,208.62z"/>
				<path class="st12" d="M312.29,235.2c0.01-9.32,0.01-9.31,9.22-9.32c3.32,0,6.65-0.2,9.82,1.15c3.77,1.6,5.84,4.59,5.83,8.51
	c-0.02,3.74-2.13,6.64-5.69,8.07c-5.77,2.32-11.84,0.95-17.78,1.17c-1.85,0.07-1.35-1.54-1.38-2.6
	C312.25,239.86,312.29,237.53,312.29,235.2z"/>
				<path class="st13" d="M359.27,243.94c0.06-10.33,8.54-19.07,18.47-19.05c10,0.02,18.7,9.19,18.54,19.55
	c-0.15,10.27-8.71,19.02-18.55,18.98C367.49,263.39,359.2,254.64,359.27,243.94z"/>
			</svg>

		</Box>
	);

	if (disabledLink) {
		return logo;
	}

	return (
		<Link component={RouterLink} href="/login" sx={{ display: 'contents' }}>
			{logo}
		</Link>
	);
});

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default Logo;
