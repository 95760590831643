import React from 'react';


// import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { useResponsive } from 'hooks/use-responsive';

import { bgBlur } from 'theme/css';

import Iconify from 'components/iconify';

import Searchbar from './common/searchbar';
import { NAV, HEADER } from './config-layout';
import AccountPopover from './common/account-popover';
// import LanguagePopover from './common/language-popover';
import NotificationsPopover from './common/notifications-popover';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginResponse: [],
      UserLoggedIn: false,
      userType: "",
      lgUp: "up",
    };
  }
  componentDidMount() {
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.loginResponse !== this.state.loginResponse) {

    }
  }



  render() {

    // export default function Header({ onOpenNav }) {
    const theme = this.props.theme;

    const lgUp =this.props.lgUp;

    const renderContent = (
      <>
        {!lgUp && (
          <IconButton onClick={this.props.onOpenNav} sx={{ mr: 1 }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}

        <Searchbar />

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={1}>
       
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </>
    );

    return (
      <AppBar
        sx={{
          boxShadow: 'none',
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(lgUp && {
            width: `calc(100% - ${NAV.WIDTH + 1}px)`,
            height: HEADER.H_DESKTOP,
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
          }}
        >
          {renderContent}
        </Toolbar>
      </AppBar>
    );
  }
}
function withHooks(WrappedComponent) {
  return function(props) {
    const theme = useTheme();
    const lgUp = useResponsive('up', 'lg');
    return (
      < WrappedComponent theme={theme} lgUp={lgUp} {...props} />
    );
  };
}
export default withHooks(Header);

// export default Header;

// Header.propTypes = {
//   onOpenNav: PropTypes.func,
// };
