import React from 'react';
import PropTypes from 'prop-types';
// import { set, sub } from 'date-fns';
// import { faker } from '@faker-js/faker';
import axios from "axios";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
// import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import AuthService from "services/auth.service";
import { Link } from "react-router-dom";

import { fToNow } from 'utils/format-time';

import Iconify from 'components/iconify';
import Scrollbar from 'components/scrollbar';


class NotificationsPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      logout: false,
      notices: [],
    };
  }
  componentDidMount() {
    axios
    .get('https://school-api.atomiot.co.ke/v1/school/get/notices?schoolId=' + AuthService.getCurrentUser().schoolId)
    .then((res) => {
      this.setState({ notices: res.data });
      console.log(" all notices : ", res.data);
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.loginResponse !== this.state.loginResponse) {

    }
  }
  handleOpen = (event) => {
    this.setState({
      open: event.currentTarget,

    });
  };

  handleClose = () => {
    this.setState({
      open: null,

    });
  };
  logoutFromApp = () => {
    AuthService.logout()
    this.setState({
      logout: true,
    });
  };

  handleMarkAllAsRead = () => {
    this.setState({
      notices: this.state.notices.map((notification) => ({
        ...notification,
        isUnRead: false,
      })),

    });

  };

  render() {


    const totalUnRead = this.state.notices.filter((item) => item.isAdminApproved === false  ).length;

    return (
      <>
        <IconButton color={this.state.open ? 'primary' : 'default'} onClick={this.handleOpen}>
          <Badge badgeContent={totalUnRead} color="error">
            <Iconify width={24} icon="solar:bell-bing-bold-duotone" />
          </Badge>
        </IconButton>

        <Popover
          open={!!this.state.open}
          anchorEl={this.state.open}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              mt: 1.5,
              ml: 0.75,
              width: 360,
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">  School Notices</Typography>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                You have {totalUnRead} unread messages
              </Typography> */}
            </Box>

            {/* {totalUnRead > 0 && (
              <Tooltip title=" Mark all as read">
                <IconButton color="primary" onClick={handleMarkAllAsRead}>
                  <Iconify icon="eva:done-all-fill" />
                </IconButton>
              </Tooltip>
            )} */}
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
           
            <List
              disablePadding
              // subheader={
              //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
              //     School Notices
              //   </ListSubheader>
              // }
            >
              {this.state.notices.filter(obj => obj.isAdminApproved === false).slice(0, 5).map((props, key) => (
                <Link to="/school/notices" style={{ textDecoration: 'none' }}>
                   <NotificationItem key={key} notification={props} />
                 </Link>
               
              ))}
            </List>
          </Scrollbar>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Box sx={{ p: 1 }}>
          <a href="/school/notices" >
            <Button fullWidth disableRipple>
              View All
            </Button>

            </a>
          </Box>
        </Popover>
      </>
    );
  }
}

export default NotificationsPopover;

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isRead: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  console.log("notification : ", notification);
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{'/assets/images/avatars/avatar_1.jpg'}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.message}
      </Typography>
    </Typography>
  );

  return {
    avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
    title,
  };
}
